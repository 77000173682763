import { createContext, useContext, useEffect, useState } from 'react';
import { loadLanguage, en } from 'lang';
import { get } from 'ui/lodash';

const cache = {};

export const LanguageContext = createContext({
  language: 'en',
  setLanguage: () => {},
});

export function useLanguageContext() {
  return useContext(LanguageContext);
}

export function useLanguage() {
  return useContext(LanguageContext).language;
}

export function useSetLanguage() {
  const { setLanguage, language } = useLanguageContext();
  return (lang) => {
    if (lang !== language) {
      setLanguage(lang);
    }
  };
}

export function useLanguageLoader() {
  const [state, setState] = useState(en);
  const language = useLanguage();

  const load = ()=>{
    if (cache[language]) {
      setState(cache[language]);
    } else {
      loadLanguage(language).then(({default: data}) => {
        cache[language] = data;
        setState(data);
      });
    }
  };

  useEffect(() => {
    if(language === 'en') {
      return;
    }
    load();
  }, [language]);

  return (term) => get(state, term, get(en, term, ''));
}
