const en = {
  "store": {
    "menu": {
      "catalogs": "Catalogs",
      "products": "Products",
      "contact": "Contact",
      "aboutUs": "About Us",
      "termsPolicies": "Terms & Policies",
      "storeTimings": "Store Timings",
      "paymentMethod": "Payment Methods",
      "online": "Online",
      "cash": "Cash On Delivery",
      "contactUs": "Contact Us",
      "followUsOn": "Follow Us On",
      "shop": "Shop",
      "payments": "Payments",
      "shipping": "Shipping",
      "address": "Address",
      "myAccount": "My Account",
      "orders": "Orders",
      "messages": "Messages",
      "login": "Login",
      "logout": "Logout",
      "information": "Information",
      "termsPrivacy": "Terms & Privacy Policy",
      "additionalCharges": "Additional Charges",
      "shopPolicies": "Shop Policies",
      "collections": "Collections",
      "home": "Home"
    },
    "headings": {
      "featuredProducts": "Featured Products",
      "featuredCollections": "Featured Collections",
      "contactUs": "Contact Us",
      "followUs": "Follow us on",
      "paymentPartners": "Our Payment Partners",
      "storeInformation": "Store Information",
      "seamlessPayments": "Seamless Payments",
      "fastShipping": "Fast Shipping",
      "orderTracking": "Order Tracking",
      "copyRight": "CopyRight",
      "collections": "COLLECTIONS",
      "popularCategories": "POPULAR CATEGORIES",
      "viewAll": "View All",
      "productDetails": "Product Details",
      "productDescription": "Product Description",
      "addCustomization": "Add Customization",
      "customization": "Customization",
      "customizationText": "Customization Text",
      "enterCustomizationText": "Enter Customization Text",
      "youMayAlsoLike": "You May Also Like",
      "estimatedDeliveryTime": "Estimated Delivery Time",
      "extraChargesMayApply": "Extra charges may apply",
      "typeSomethingToSearch": "Type something to search",
      "weCantFindAnyItemMatchingYourSearch": "We can’t find any item matching your search",
      "noResultsFound": "No Results Found",
      "noConversation": "Look’s like you haven’t started any conversation yet",
      "noMessage": "No Messages Yet",
      "typeYourMesssage": "Type Your Message...",
      "noSavedAddress": "You dont have any address saved!",
      "addNewAddress": "ADD NEW ADDRESS",
      "myAddress": "My Address",
      "selectAddress": "Select Address",
      "enterLocation": "Enter Location",
      "selectNearbyLocation": "Select Nearby Location",
      "orderConfirmed": "Order Confirmed",
      "thanksForOrder": "Thanks for your Order",
      "orderSuccess": "Your order has been placed successfully",
      "orderId": "Your Order ID",
      "trackOrder": "Track Order",
      "backToHome": "Back To Home",
      "peopleAlso": "People Also Bought This",
      "modeOfPayment": "Mode Of Payment",
      "clearHistory": "Clear History",
      "bestSellers": "BEST SELLERS",
      "thisJustIn": "THIS JUST IN",
      "shopHoursInformation": "SHOP HOURS & INFORMATION",
      "links": "LINKS",
      "payments": "PAYMENTS",
      "popular": "POPULAR",
      "categories": "CATEGORIES"
    },
    "contact": {
      "name": {
        "label": "Name",
        "placeholder": "Enter your name"
      },
      "email": {
        "label": "Email ID",
        "placeholder": "Enter your E-mail ID"
      },
      "phone": {
        "label": "Phone Number",
        "placeholder": "Enter your phone number"
      },
      "message": {
        "label": "Message",
        "placeholder": "Type your message here"
      }
    },
    "words": {
      "enterQuantity": "Enter Quantity",
      "maxQuantity": "Max Quantity",
      "selectGroup": "Select Group",
      "madeWith": "made with",
      "using": "using",
      "catalogs": "Catalogs",
      "filters": "Filters",
      "customization": "Customization",
      "customizationText": "Customization Text",
      "selectColor": "Select Color",
      "availableSizes": "Available Sizes",
      "outOfStock": "Out of Stock",
      "leftInStock": "Left in Stock",
      "only": "Only",
      "select": "Select",
      "quantity": "Quantity",
      "subTotal": "Subtotal",
      "item": "Item",
      "searchProducts": "Search products",
      "chat": "Chat",
      "cart": "Cart",
      "customerTestimonials": "Customer Testimonials",
      "checkout": "CHECKOUT",
      "placeOrder": "PLACE ORDER",
      "proceedToPay": "PROCEED TO PAY",
      "rewards": "Rewards",
      "deliverHere": "Deliver Here",
      "completePayment": "COMPLETE PAYMENT",
      "paymentMode": "CHOOSE PAYMENT MODE",
      "reviewOrder": "REVIEW ORDER",
      "items": "Items",
      "shippingModes": "Shipping Modes",
      "delivery": "Delivery",
      "selfPickup": "Self Pickup",
      "pickupAddress": "Pickup Address",
      "contactDetails": "Contact Details",
      "deliveryTo": "Delivery To",
      "addressBook": "Address Book",
      "addNew": "Add New",
      "addressForDelivery": "Select address for delivery",
      "selectedAddress": "Selected Address",
      "changeAddess": "Change or Add Address",
      "orderSummery": "Order Summary",
      "applyCoupon": "Apply Coupon",
      "couponApplied": "Coupon Applied",
      "apply": "Apply",
      "itemTotal": "Item Total",
      "discount": "Discount",
      "billingDetails": "Billing Details",
      "shippingFee": "Shipping Fee",
      "tax": "Tax",
      "couponDiscount": "Coupon Discount",
      "amountToPay": "Amount to Pay",
      "back": "Back",
      "yourAddress": "Your Address",
      "viewBilling": "View Billing Details",
      "placeOrderPay": "Place Order & Pay",
      "delete": "DELETE",
      "cancel": "Cancel",
      "enterCode": "Enter Coupon Code",
      "paymentFee": "Payment Fee",
      "privacyPolicy": "Privacy Policy",
      "termsConditions": "Terms & Conditions",
      "additionalCharges": "Additional Charges",
      "directPayments": "Direct Payments",
      "confirmPayment": "CONFIRM PAYMENT",
      "payVia": "Pay Via",
      "error": "error",
      "sort": "Sort",
      "pleasePay": "Please pay ",
      "inAdvance": "in advance",
      "makePayment": "MAKE PAYMENT",
      "advancePayment": "Advance Payment",
      "paymentConfirmedAmount": "Payment Confirmed Amount",
      "totalPayable": "Total Payable",
      "confirmedPayment": "Confirmed Payment",
      "paymentDueInfo": "Ignore if already paid, this will get updated once seller confirms the payment",
      "viewCart": "VIEW CART",
      "hello": "Hello",
      "productName": "Product Name",
      "price": "Price",
      "writeReview": "Write a Review",
      "userName": "User Name",
      "emailIdOptional": "Email ID (optional)",
      "digitalShipping": "Digital Shipping",
      "total": "Total",
      "payment": "Payment",
      "addNewAddress": "Add New Address"
    },
    "snackBar": {
      "updatedAddressSuccessful": "Updated address successful",
      "addedNewAddressSuccessful": "Added new address successful"
    },
    "alerts": {
      "contactDetails": "Please fill the contact details",
      "invalidCoupon": "Invalid Coupon Code",
      "storeTime": "Please check the store timings in shop description and try again later.",
      "noOrders": "You haven’t added any items to your cart yet!",
      "preferredAddress": "Please select your preferred shipping address",
      "noAddressSaved": "You dont have any address saved yet!",
      "deleteCart": "Eep! Are you sure you want to delete this item from your cart?",
      "notAvailableforCoupon": "Not available for the coupon applied",
      "uploadScreenshot": "Please upload payment screenshot",
      "noTncs": " This shop has not set any Privacy Policy.",
      "iFoundThis": "I found this really awesome product of",
      "checkOutHere": "Check out here"
    },
    "form": {
      "enter": "Enter",
      "fullName": "Full Name",
      "enterName": "Enter Name",
      "phoneNumber": "Phone Number",
      "enterPhone": "Enter Phone Number",
      "alternativeNumber": "Alternative Number",
      "enterAlternativePhone": "Enter Alternative Phone Number",
      "pincode": "Pincode",
      "postalcode": "Postal Code",
      "zipcode": "Zipcode",
      "enterPincode": "Enter Pincode",
      "country": "Country",
      "enterCountry": "Enter Country",
      "state": "State",
      "enterState": "Enter State",
      "enterCity": "Enter City",
      "city": "City",
      "doorno": "Door No",
      "buildingName": "Building Name",
      "text": "Text",
      "area": "Area",
      "lacality": "Locality",
      "street": "Street",
      "landmark": "Landmark",
      "optional": "Optional",
      "enterLandmark": "Enter Landmark",
      "saveAddressType": "Save Address Type",
      "home": "Home",
      "office": "Office",
      "others": "Others",
      "saveAddressAs": "Save address as",
      "location": "Location",
      "searchLocation": "Search Location",
      "fullAddress": "Full Address",
      "addressLine1": "Address line 1",
      "addressLine2": "Address line 2",
      "enterFullAddress": "Enter Full Address",
      "enterEmailID": "Enter Email ID",
      "email": "Email ID",
      "addNew": "Add New"
    }
  },
  "common": {
    "more": "More",
    "shop": "Shop",
    "viewAll": "View All",
    "browse": "Browse",
    "browseAll": "Browse All",
    "products": "products",
    "continue": "Continue"
  },
  "days": {
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday"
  },
  "buttons": {
    "submit": "Submit",
    "add": "Add",
    "clearFilters": "Clear Filters",
    "apply": "Apply",
    "updateCart": "Update Cart",
    "addToCart": "Add To Cart",
    "buyNow": "BUY NOW",
    "yes": "Yes",
    "no": "No",
    "checkout": "Checkout",
    "loadMore": "Load More",
    "saveAndProceed": "Save & Proceed",
    "okay": "OKAY",
    "soldOut": "SOLD OUT",
    "soldOut1": "Sold Out",
    "addToBag": "Add to bag",
    "viewAll": "View All"
  },
  "orders": {
    "myOrders": "My Orders",
    "orderedOn": "Ordered on",
    "multiItem": "Multi Item",
    "viewShop": "View Shop",
    "viewOrder": "View Order",
    "soldBy": "Sold By",
    "orderView": "Order View",
    "orderId": "Order ID",
    "groupOrderId": "Group Order ID",
    "orderStatus": "Order Status",
    "tackOrder": "Track Order",
    "priceDetails": "Price Details",
    "productPrice": "Product Price",
    "discount": "Discount",
    "paymentFee": "Payment Fee",
    "shippingFee": "Shipping Fee",
    "totalPrice": "Total Product Price",
    "paymentMode": "Mode of Payment",
    "noOrdersYet": "You haven’t ordered any items yet!",
    "updating": "Payment Processing...",
    "update": "update",
    "shippingOptions": "Shipping Options",
    "paymentDue": "Payment Due",
    "paymentPending": "Payment Pending Confirmation",
    "extraCharges": "Extra Customization Charges",
    "pickup": "Pickup",
    "address": "Address",
    "shipping": "Shipping",
    "trackingDetails": "Tracking Details",
    "trackingStatus": "Tracking Status",
    "ItemList": "Item List",
    "itemDetails": "Item Details",
    "totalProducts": "Total Products",
    "totalQuantity": "Total Quantity",
    "viewNote": "View Note",
    "paymentDetails": "Payment Details",
    "value": "Value",
    "taxName": "Tax Name",
    "package": "Package",
    "totalAmountPaid": "Total Amount Paid",
    "totalProductDiscount": "Total Product Discount",
    "totalAmountPayable": "Total Amount Payable",
    "shippingCharges": "Shipping Charges",
    "customizationFee": "Customization Fee",
    "couponDiscount": "Coupon Discount",
    "paymentGatewayFd": "Payment Gateway Fee Discount",
    "paymentGatewayFee": "Payment Gateway Fee",
    "refundedAmount": "Refunded Amount",
    "directPayment": "Direct Payment",
    "paymentScreenshot": "Payment Screenshot",
    "qty": "Qty",
    "colors": "Colors",
    "statusHistory": "Status History",
    "status": "Status",
    "dateTime": "Date & Time",
    "updateStatusNote": "Update Status Note",
    "customerNote": "Customer Note",
    "your": "Your",
    "details": "Details",
    "mode": "Mode",
    "amount": "Amount",
    "paymentDate": "Payment Date",
    "rateThisProduct": "How would you like to rate this product ?",
    "reviewTitle": "Enter Review Title",
    "reviewPlaceHolder": "Give your review a crisp heading",
    "reviewProductHere": "Review the Product Here",
    "reviewProductHerePlaceHolder": "Tell us what you thought about this product!",
    "reviewSuccess": "Product rated successfully",
    "submit": "Submit",
    "ratedAlready": "You have already rated this product",
    "review": "Review",
    "rating": "Rating",
    "reviews": "Reviews",
    "ratingReviewHead": "Ratings & Reviews",
    "moreReviews": "More Reviews",
    "terrible": "Terrible",
    "bad": "Bad",
    "alright": "Alright",
    "good": "Good",
    "excellent": "Excellent",
    "orderText": "Order",
    "paymentText": "Payment",
    "shippingText": "Shipping"
  }
}

export default en;