import { createContext, useContext } from 'react';

export const ProfileContext = createContext({
  profile: null,
  domain: {
    custom: false,
  },
  shop: null,
  showLogin: () => {
  },
  toggleFooter: () => {
  },
  isDesktop: false,
  refreshProfile: () => {},
});

export function useProfile() {
  return useContext(ProfileContext).profile;
}

export function useProfileEmail() {
  const profile = useProfile();
  if(profile?.email && !/^guest-.*@windo\.live$/.test(profile.email)) {
    return profile.email;
  }
  return '';
}

export function useShowLogin(shop={}) {
  const { showLogin } = useContext(ProfileContext);
  return (...data) => {
    showLogin(shop, ...data);
  };
}

export function useIsGuest() {
  const profile = useProfile();
  return !!profile?.isGuest;
}

export function useRefreshProfile() {
  const { refreshProfile } = useContext(ProfileContext);
  return refreshProfile;
}

export function useToggleFooter() {
  return useContext(ProfileContext).toggleFooter;
}

export function useProfileId() {
  return useProfile()?.userName || '';
}

export function useIsVisitor() {
  return !!useProfile()?.visitor;
}

export function useIsProfileLoading() {
  return !!useProfile()?.isLoading;
}

export function toggleTheme() {
  return useContext(ProfileContext).toggleTheme;
}

export function isDarkMode() {
  return useContext(ProfileContext).isDarkMode;
}

export function isUserIdle() {
  return useContext(ProfileContext).isIdle;
}

export function useIsCustomDomain() {
  return !!useContext(ProfileContext)?.domain?.custom;
}

export function useCustomDomain() {
  return useContext(ProfileContext)?.domain?.host;
}

export function useIsCustomShop() {
  return useContext(ProfileContext)?.shop;
}

export function useIsDesktop() {
  return useContext(ProfileContext)?.isDesktop;
}

export function useSetWebViewAlert() {
  return useContext(ProfileContext)?.setWebViewAlert;
}
