import sortBy from 'lodash-es/sortBy';
import sumBy from 'lodash-es/sumBy';
import uniqBy from 'lodash-es/uniqBy';
import reduce from 'lodash-es/reduce';
import invert from 'lodash-es/invert';
import sum from 'lodash-es/sum';
import pick from 'lodash-es/pick';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import clone from 'lodash-es/clone';
import debounce from 'lodash-es/debounce';
import groupBy from 'lodash-es/groupBy';
import uniq from 'lodash-es/uniq';
import chunk from 'lodash-es/chunk';
import range from 'lodash-es/range';

const _ = {
  sortBy,
  uniqBy,
  reduce,
  invert,
  sum,
  sumBy,
  pick,
  get,
  isEmpty,
  clone,
  debounce,
  groupBy,
  uniq,
  chunk,
  range,
};

export {
  sortBy,
  uniqBy,
  reduce,
  invert,
  sum,
  sumBy,
  pick,
  get,
  isEmpty,
  clone,
  debounce,
  groupBy,
  uniq,
  chunk,
  range,
};

export default _;
