import en from './en';

export {
  en,
}

export async function loadLanguage(lang) {
  if(lang === 'en') {
    return en;
  }
  try{
    const ln = await import(`./${lang}`);
    return ln;
  }catch (e) {
    return en;
  }
}
