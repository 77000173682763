var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"LUdcrdZbnpj2jZbdNQ8Ja"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://5508bc255ea04905a8ca759f71440575@o492251.ingest.sentry.io/5559225',
  environment: process.env.ENV,
  tracesSampleRate: 0.2,
  enabled: process.env.ENV !== 'development',
  ignoreErrors: [
    /Request failed with status code 404/,
    /Blocked a frame with origin/,
    /Can't find variable: _AutofillCallbackHandler/,
    /NotReadableError: The I\/O read operation failed\./
  ],
  allowUrls: [
    /.*\.mywindo\.site\/.*/,
    /.*\.mywindo.shop\/.*/,
    /.*\.windo.live\/.*/
  ]
});
