import { useEffect, useState } from 'react';
import { Loader } from 'ui/services';
import Snackbar from 'ui/services/snackbar';
import { getProfile } from 'ui/api/user';

export function useProfile() {
  const [profile, setProfile] = useState({ visitor: true, isLoading: true });
  const [isLoading, setIsLoading] = useState(true);

  const refreshProfile = async () => {
    Loader.show();
    setIsLoading(true);
    try {
      const data = await getProfile();
      setProfile(data);
    } catch (e) {
      Snackbar.showError(e);
    } finally {
      setIsLoading(false);
      Loader.hide();
    }
  };

  useEffect(() => {
    refreshProfile();
  }, []);

  return [profile, refreshProfile, isLoading];
}
