import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Button } from 'phoenix-components';
import styles from './Error.module.css';

export function CustomError({ code }) {

  const router = useRouter();
  const fiveXX = code >= 500;
  return (
    <div className={styles.container}>
      <img className={styles.img} src={fiveXX ? '/images/error/500.svg' : '/images/error/404.svg'} alt="" />
      {fiveXX && (
        <div className={styles.errorContainer}>
          <div className={styles.h1}>
            Network connectivity issue. Please check your internet and try again...
          </div>
          <div className={styles.errorMessage}>
            We are working on fixing the problem, please try again
          </div>
        </div>
      )}
      {!fiveXX && (
        <div className={styles.errorContainer}>
          <div className={styles.h1}>
            Page not found..
          </div>
          <div className={styles.errorMessage}>
            The page is missing or the link is broken.
          </div>
        </div>
      )}
      <Button
        onClick={() => router.back()}
        type="large"
        label="Go Back"
      />
    </div>
  );

}

CustomError.propTypes = {
  code: PropTypes.number,
};
CustomError.defaultProps = {
  code: 500,
};
