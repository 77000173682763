/* eslint-disable react/jsx-props-no-spreading,max-len */
import 'styles/globals.css';
import 'styles/icons.css';
import cookie from 'cookie';
import { ProfileContext } from 'contexts/profile';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useIsDesktopJs, useRemoveJss } from 'hooks/removeJss';
import theme from 'layouts/theme';
import PropTypes from 'prop-types';
import SnackBar from 'components/shared/Snackbar';
import LoaderModal from 'components/shared/LoaderModal';
import React, { useEffect, useState } from 'react';
import { Storage } from 'ui/services';
import { useIdleTimer } from 'react-idle-timer';
import ErrorBoundary from 'components/error/ErrorBoundary';
import { CustomError } from 'components/error/Error';
import { LanguageContext } from 'contexts/language';
import { useRouterEvents } from 'hooks/router';
import Script from 'next/script';
import CONFIG from 'ui/config';
import dynamic from 'next/dynamic';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useProfile } from 'hooks/profile';
import { init as initApm } from '@elastic/apm-rum';
import { useQueryParams } from 'hooks/location';
import { Dialog, DialogContent } from '@mui/material';


dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

const Login = dynamic(() => import('components/login/Login'), {
  // ssr: false,
});

if (typeof window !== 'undefined' && CONFIG.env !== 'development') {
  initApm({
    serviceName: 'debby',
    serverUrl: 'https://observability-prod-deployment.apm.ap-south-1.aws.elastic-cloud.com',
    serviceVersion: CONFIG.BUILD_ID,
    environment: CONFIG.env,
    enabled: CONFIG.env !== 'development',
  });
}

function MyApp({
  Component,
  pageProps
}) {
  const {
    ...otherPageProps
  } = pageProps;
  const query = useQueryParams();
  const [showLogin, setShowLogin] = useState(query.has('login'));
  const [hideFooter, setHideFooter] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isIdle, setIsIdle] = useState(false);
  const isDesktop = useIsDesktopJs();
  const [lang, setLang] = useState('');
  const [profile, refreshProfile] = useProfile();
  const [openWebViewAlert, setWebViewAlert] = useState(false);

  useRouterEvents();

  const handleOnIdle = () => {
    setIsIdle(true);
  };

  const handleOnActive = () => {
    setIsIdle(false);
  };

  const handleOnAction = () => {
    setIsIdle(false);
  };

  useIdleTimer({
    timeout: 300000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });

  useRemoveJss();

  useEffect(() => {
    const locale = Storage.getItem('lang');
    if (locale?.length && locale !== lang) {
      setLang(locale);
    }
  }, []);

  const toggleFooter = () => {
    setHideFooter(!hideFooter);
  };

  const toggleTheme = (theme) => {
    const changeTo = theme === 'dark';
    setIsDarkMode(changeTo);
    const ele = document.getElementsByTagName('html')[0];
    ele.className = changeTo ? 'dark' : 'light';
    Storage.setItem('theme', ele.className);
  };

  const setLanguage = locale => {
    if (lang === locale) {
      return;
    }
    localStorage.setItem('lang', locale);
    setLang(locale);
  };

  const openLogin = (shop, redirectUrl) => {
    if (redirectUrl === true || redirectUrl === false) {
      redirectUrl = null;
    }
    setShowLogin(shop);
  };

  if (pageProps.errorCode) {
    return <CustomError code={pageProps.errorCode}/>;
  }

  const getLayout = Component.getLayout || ((page) => page);

  return (
    <ProfileContext.Provider
      value={{
        ...otherPageProps,
        profile,
        toggleFooter,
        toggleTheme,
        isDarkMode,
        isIdle,
        showLogin: openLogin,
        isDesktop,
        refreshProfile,
        setWebViewAlert,
      }}>
      <LanguageContext.Provider
        value={{
          language: lang,
          setLanguage,
        }}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <>
                {openWebViewAlert && (
                  <Dialog open={true} maxWidth={'sm'}>
                    <DialogContent>
                      This app is no longer available. <br/>
                      Please contact seller for more details.
                    </DialogContent>
                  </Dialog>
                )}
                <Script
                  strategy={'beforeInteractive'}
                  id={'webview'}
                  src={'/webview.js'}
                />
                <Script strategy={'afterInteractive'} id={'root-google-tag-manager'}>
                  {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${CONFIG.GTAG}')
              `}
                </Script>
                <Script src="https://apis.google.com/js/platform.js" strategy={'afterInteractive'}/>
                {getLayout(<Component {...pageProps} />)}
                {
                  (showLogin) && (
                    <Login
                      shop={showLogin}
                      onClose={async () => {
                        setShowLogin(false);
                      }}
                    />
                  )
                }
                {pageProps?.shop?.plugins?.map((plugin, index) => (
                  <React.Fragment key={index}>
                    <div dangerouslySetInnerHTML={{ __html: plugin.code }}/>
                  </React.Fragment>
                ))}
                <SnackBar/>
                <LoaderModal/>
                <Script
                  strategy={'afterInteractive'}
                  id={'ga-analytics'}
                  src={`https://www.googletagmanager.com/gtag/js`}
                />
                <Script
                  strategy={'afterInteractive'}
                  id={'ga-analytics-2'}
                  dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                        window.dataLayer.push(arguments);
                    }
                    gtag('js', new Date());`
                  }}
                />
              </>
            </ErrorBoundary>
          </ThemeProvider>
        </StyledEngineProvider>
      </LanguageContext.Provider>
    </ProfileContext.Provider>
  );

}

MyApp.getInitialProps = async ({ ctx }) => {
  const isServer = !!ctx.req;
  if (isServer) {
    const { isCustomDomain } = require('common/domain');

    if (ctx.asPath.startsWith('/login')) {

      return {
        pageProps: { visitor: true }
      };

    }

    ctx.req.state = {};

    try {
      const cookies = cookie.parse(ctx.req.headers.cookie || '');
      if (cookies.windoSession) {
        ctx.req.state = {
          token: cookies.windoSession,
        };
      }

      const { host } = ctx.req.headers;
      const customDomain = isCustomDomain(host);

      ctx.req.state.customDomain = customDomain;
      ctx.req.state.domain = host.split(':')[0];

      const domain = {
        custom: customDomain,
        subDomain: host.endsWith('mywindo.site'),
      };

      if (customDomain) {
        domain.host = host;
      }

      const pageProps = {
        domain,
      };

      return { pageProps };

    } catch (e) {
      console.error(e);
      if (e.isAxiosError) {
        return { pageProps: { errorCode: e?.response?.status || 500 } };
      }
      return { pageProps: { errorCode: 500 } };
    }
  }
  return {
    pageProps: {},
  };

};

MyApp.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default MyApp;
