function setItem(name, value) {
  localStorage.setItem(name, value);
}

function getItem(name) {
  return localStorage?.getItem(name);
}

function setSessionItem(name, value) {
  sessionStorage.setItem(name, value);
}

function getSessionItem(name) {
  return sessionStorage.getItem(name);
}

const Storage = {
  getItem,
  setItem,
  setSessionItem,
  getSessionItem
};

export default Storage;
