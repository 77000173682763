import CONFIG from 'ui/config';
import { connector } from 'server/api/external/client';

const getCountry = async () => {
  const { data } = await connector.get('https://api.bigdatacloud.net/data/ip-geolocation', {
    params: {
      key: CONFIG.BIGDATACLOUDKEY,
      localityLanguage: 'en',
    },
  });
  return data?.country ?? {};
};

const External = {
  getCountry,
};

export default External;
