import _ from 'ui/lodash'
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';

export const getCurrency = (number, currency) => {
  if (number && currency) {
    return `${getSymbolFromCurrency(currency) || currency} ${(+number).toFixed(2).toLocaleString()}`;
  }
  return '0';
};

export const getFlat = (item) => {
  const {
    amount,
    discountedAmount
  } = item;
  const percent = (((amount - discountedAmount) * 100) / amount).toFixed();
  return percent > 0 ? `${percent}% OFF` : '';
};

function longDateFormat(date, hideTime = false) {
  return hideTime ? dayjs()
    .format('ll') : dayjs(date)
    .format('LLL');
}

export function mapOrderHistory(statusHistory, trackingHistory = [], edd) {
  const outForDelivery = trackingHistory.find(x => x.status === 'out for delivery');
  const delivered = trackingHistory.find(x => x.status === 'delivered')
    || statusHistory.find(x => x.status === 'delivered');
  const cancelled = trackingHistory.find(x => x.status === 'cancelled');
  const sorted = [..._.sortBy(statusHistory, 'at'), ..._.sortBy(statusHistory, 'date')];
  let history = (
    sorted.filter(x => x.status !== 'orderedOn') || []
  ).map(({
    status,
    at: date,
    date: other
  }) => {
    switch (status) {
      case 'payment successful':
        return {
          status,
          date: `Paid on ${longDateFormat(date || other)}`,
          color: 'yellow',
          at: date || other
        };
      case 'confirmed':
        return {
          status: 'Order Confirmed',
          date: `Confirmed on ${longDateFormat(date || other)}`,
          color: 'yellow',
          at: date || other
        };
      case 'orderedOn':
        return {
          status: 'Ordered on',
          date: `${longDateFormat(date || other)}`,
          color: 'yellow',
          at: date || other
        };
      case 'ready to ship':
        return {
          status: 'Order is Ready to Ship',
          date: longDateFormat(date || other),
          color: 'yellow',
          at: date || other
        };
      default:
        return {
          status,
          date: longDateFormat(date || other),
          color: 'yellow',
          at: date || other
        };
    }
  })
    .reverse();

  if (trackingHistory.length < 2 && history.length > 0) {
    history[0].color = 'yellow';
  }

  const [, ...rest] = trackingHistory;

  const readyToShip = (statusHistory || []).find(x => x.status === 'ready to ship');

  if (!readyToShip) {
    history.unshift({
      status: 'Order is ready to ship',
      date: '',
      color: 'grey',
    });
  }

  const restHistory = rest.map(s => ({
    status: s.status,
    date: longDateFormat(s.at),
    color: 'yellow',
    at: s.at || s.date
  }))
    .reverse();

  if (restHistory.length > 0) {
    restHistory[0].color = 'green';
  }

  history.unshift(...restHistory);

  if (!cancelled) {
    if (!outForDelivery && !delivered) {
      history.unshift({
        status: 'Out For Delivery',
        date: '',
        color: 'grey',
      });
    }

    const deliveryStatus = history.find(x => x.status === 'delivered');
    history = history.filter(x => x.status !== 'delivered');

    if (!delivered) {
      history.unshift({
        status: 'Delivered',
        date: edd ? `Estimated delivery date ${longDateFormat(edd, true)}` : '',
        color: 'grey',
      });
    } else {
      history.unshift(deliveryStatus);
    }
  }

  return _.uniqBy(history, 'status');
}

export function getUrl(path = '') {
  return `${window.location.protocol}//${window.location.host}${path}`;
}

export function injectJS(id, src) {
  return new Promise((resolve, reject) => {
    if (!document) {
      resolve();
      return;
    }

    if (document.getElementById(id)) {
      resolve('JS already loaded.');
      return;
    }

    const script = document.createElement('script');

    script.id = id;
    script.async = true;
    script.defer = true;
    script.src = src;

    script.addEventListener('load', () => resolve('JS loaded.'));

    script.addEventListener('error', () => reject(new Error('unable to load script')));
    script.addEventListener('abort', () => reject(new Error('unable to load script')));

    document.head.appendChild(script);
  });
}

export function getAddressFromComponents(addressComponents, geometry) {
  const shouldBeComponent = {
    pincode: ['postal_code'],
    addressLine2: ['street_address', 'route'],
    state: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4'
    ],
    country: ['country']
  };

  const address = {
    addressLine2: '',
    pincode: '',
    state: '',
    city: '',
    country: '',
    coords: {
      lat: geometry.location.lat(),
      long: geometry.location.lng(),
    },
  };
  addressComponents.forEach(component => {
    // eslint-disable-next-line no-restricted-syntax
    for (const shouldBe in shouldBeComponent) {
      if (shouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === 'country') {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  return address;
}

export const isOnlineEnabled = (data) => data?.paymentModes?.online?.enabled || false;

export const transformPaymentModes = (shopData, couponData) => {
  if (couponData) {
    return {
      paymentModes: {
        online: {
          enabled: !isOnlineEnabled(shopData) ? false : couponData.online,
          showDisabled: isOnlineEnabled(shopData) ? false : !couponData.online,
          charges: shopData?.paymentModes?.online?.charges || 0,
          isEligible : shopData?.paymentModes?.online?.isEligible && couponData.online,
        },
        cod: {
          enabled: couponData.cod,
          showDisabled: !couponData.cod,
          charges: shopData?.paymentModes?.cod?.charges || 0,
          isEligible : shopData?.paymentModes?.cod?.isEligible && couponData.cod,
          partial:{
            enabled:shopData?.paymentModes?.cod?.partial?.enabled,
            value:shopData?.paymentModes?.cod?.partial?.value,
            isEligible:shopData?.paymentModes?.cod?.partial?.isEligible && couponData.cod,
          }
        },
        custompayment: {
          enabled: couponData.custompayment,
          showDisabled: !couponData.custompayment,
          configured: shopData?.paymentModes?.custompayment?.configured || [],
        },
      }
    };
  }
  if (shopData) {
    if (!isOnlineEnabled(shopData)) {
      shopData.paymentModes.online.enabled = false;
    }
    return shopData;
  }
  return {};
};

export const transformOrderPaymentModes = (shop, orderData, couponData, charges, paymentMode) => {
  if (couponData) {
    return {
      online: {
        enabled: !isOnlineEnabled(shop) ? false : couponData.online,
        showDisabled: !isOnlineEnabled(shop) ? false : !couponData.online,
        charges: charges.find(x => x.type === 'online')?.amount || 0,
      },
      cod: {
        enabled: couponData.cod,
        showDisabled: !couponData.cod,
        charges: charges.find(x => x.type === 'cod')?.amount || 0,
      },
      custompayment: {
        enabled: couponData.custompayment,
        showDisabled: !couponData.custompayment,
        configured: (shop?.paymentModes?.custompayment?.configured || []).map(x => ({
          ...x,
          charges: charges.find(y => y.value === x.mode && y.type === 'custompayment')?.amount || 0,
        }))
      },
    };
  }
  if (orderData) {
    return {
      online: {
        enabled: !isOnlineEnabled(shop) ? false : orderData.online,
        charges: charges.find(x => x.type === 'online')?.amount || 0,
        isEligible: paymentMode === 'online',
      },
      cod: {
        enabled: orderData.cod,
        charges: charges.find(x => x.type === 'cod')?.amount || 0,
        isEligible: paymentMode === 'cod',
      },
      custompayment: {
        enabled: orderData.custompayment,
        isEligible: paymentMode === 'custompayment',
        configured: (shop?.paymentModes?.custompayment?.configured || []).map(x => ({
          ...x,
          charges: charges.find(y => y.value === x.mode && y.type === 'custompayment')?.amount || 0,
        }))
      },
    };
  }
  return {};
};

export const getOrderItemsCount = (products = []) => _.sum(products.map(x => x.quantity));
