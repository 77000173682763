import { useEffect, useState } from 'react';

export function useRemoveJss() {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
}

export function useIsDesktopJs() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const onChange = () => {
      setIsDesktop(document.body.clientWidth >= 768);
    };

    window.addEventListener('resize', onChange);

    setIsDesktop(window.screen.width >= 768);

    return () => {
      window.removeEventListener('resize', onChange);
    };
  }, []);

  return isDesktop;
}

export function useIsDesktopJs1() {
  const [isDesktop, setIsDesktop] = useState(null);

  useEffect(() => {
    const onChange = () => {
      setIsDesktop(document.body.clientWidth >= 768);
    };

    window.addEventListener('resize', onChange);

    setIsDesktop(window.screen.width >= 768);

    return () => {
      window.removeEventListener('resize', onChange);
    };
  }, []);

  return isDesktop;
}

export function useDeviceWidth() {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(document.body.clientWidth);
    const onResize = () => {
      setWidth(document.body.clientWidth);
    };
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return width;
}
