import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const CONFIG = {
  GAUTH: {
    CLIENT_ID: publicRuntimeConfig.gauth,
  },
  env: publicRuntimeConfig.env,
  CASHFREE: {
    host: publicRuntimeConfig.cashfreeHost,
  },
  STRIPE: {
    key: publicRuntimeConfig.stripeKey,
  },
  FB: {
    appId: publicRuntimeConfig.fbAppId,
  },
  GTAG: publicRuntimeConfig.gtag,
  GMAPS_KEY: publicRuntimeConfig.gmap,
  BIGDATACLOUDKEY: publicRuntimeConfig.bigDataCloudKey,
  MIDTRANS: {
    host: publicRuntimeConfig.midtrans,
  },
  PAYTM: {
    host: publicRuntimeConfig.paytm,
  },
  BUILD_ID: process?.env?.CONFIG_BUILD_ID,
};

export default CONFIG;
