import { injectJS } from 'ui/lib/loadJs';

async function getStripe(key) {
  if (!window.Stripe) {
    await injectJS('stripe-js', 'https://js.stripe.com/v3');
  }
  return window.Stripe(key);
}

async function redirectToCheckout(key, sessionId) {
  const instance = await getStripe(key);

  const result = await instance.redirectToCheckout({
    sessionId,
  });

  if (result.error) {
    throw new Error(result.error.message);
  }
  return result;
}

const Stripe = {
  getStripe,
  redirectToCheckout,
};

export default Stripe;
