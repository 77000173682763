import { useEffect } from 'react';
import { Loader } from 'ui/services';
import { useRouter } from 'next/router';

export function useRouterEvents() {
  const router = useRouter();

  useEffect(() => {
    const hideLoader = () => {
      Loader.hide();
    };
    const showLoader = (url) => {
      if (!url.startsWith('/?')) {
        Loader.show();
      }
    };
    router.events.on('routeChangeStart', showLoader);
    router.events.on('routeChangeComplete', hideLoader);
    router.events.on('routeChangeError', hideLoader);

    return () => {
      router.events.off('routeChangeStart', showLoader);
      router.events.off('routeChangeComplete', hideLoader);
      router.events.off('routeChangeError', hideLoader);
    };
  }, [router]);
}
