import axios from './axios';

export const getProfile = async () => {
  const { data } = await axios.get('/user/profile');
  return data;
};

export const getAddresses = async () => {
  const { data } = await axios.get('/user/getAddress');
  return data;
};

export const addAddress = async (payload) => {
  const { data } = await axios.post('/user/addAddress', payload);
  return data;
};

export const updateAddress = async (payload) => {
  const { data } = await axios.patch('/user/updateAddress', payload);
  return data;
};

export const deleteAddress = async (payload) => {
  const { data } = await axios.patch('/user/removeAddress', payload);
  return data;
};

export const validateUserName = async (userName) => {
  const { data } = await axios.get('/user/user', {
    params: {
      userName
    }
  });
  return data;
};

export const uploadDp = async (payload) => {
  const { data } = await axios.post('/user/upload', payload);
  return data;
};

export const updateProfile = async (payload) => {
  const { data } = await axios.patch('/user/update', payload);
  return data;
};

export const sendOtp = async (payload) => {
  const { data } = await axios.post('/user/otp', payload);
  return data;
};

export const validateEmail = async (payload) => {
  const { data } = await axios.post('/user/email', payload);
  return data;
};

export const validateEmailGetToken = async (payload) => {
  const { data } = await axios.post('/user/emailGetToken', payload);
  return data;
};
