import React, { Component } from 'react';
import * as Sentry from '@sentry/nextjs';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogActions
} from '@mui/material';
import { Button } from 'phoenix-components';
import { withRouter } from 'next/router';

class ErrorBoundary extends Component {

  constructor(props) {

    super(props);
    this.state = { hasError: false };

  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {

    return { hasError: true };

  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {

    if (error?.isAxiosError) {

      return;

    }
    Sentry.captureException(error);
    console.error(error, errorInfo);

  }

  onClick = async () => {

    const { router } = this.props;
    router.back();

  }

  render() {

    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {

      return (
        <Dialog open={true} fullWidth>
          <DialogContent className="textCenter bold">
            Network connectivity issue. Please check your internet and try again.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.onClick}
              type="large"
              label="Go Back"
            />
          </DialogActions>
        </Dialog>
      );

    }
    return children;

  }

}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  router: PropTypes.any.isRequired,
};

export default withRouter(ErrorBoundary);
