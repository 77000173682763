import { red } from '@mui/material/colors';
import { createTheme, adaptV4Theme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#F5603F',
    },
    secondary: {
      main: '#ffffff',
    },
    error: {
      main: red.A400,
    },
    success: {
      main: '#388e3c',
    },
    warning: {
      main: '#f57c00',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 10,
  },
  MuiSelect: { root: { '&.MuiFilledInput-input': { color: '#a2a2a2', }, }, },
}));

export default theme;
